<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-auto">
        <div class="flex flex-col justify-center items-center py-28 md:py-36 lg:py-40" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold">METASPINS <span class="text-[#1294F0]">REGISTRATION</span></p>
            </div>
            <div v-motion-pop-visible class="hidden lg:block frame-bg w-[1180px] h-[350px] mt-24">
              <div class="flex flex-row justify-start items-center">
                <div class="flex flex-col items-start ml-14 gap-6 w-[60%] mt-10">
                    <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                        <img src="../assets/metaspins-logo.png" class="w-[260px]">
                    </div>
                    <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                        <span class="text-4xl font-bold text-white text-left uppercase">register now on metaspins.com</span>
                        <span class="text-base text-white text-left uppercase">Receive 100% deposit bonus upon registration</span>
                    </div>
                    <div style="font-family: Oxanium, sans-serif;">
                      <a href="https://metamedialinks.com/e36249d00" target="_blank">
                        <button class="text-white bg-gradient-to-r from-[#01D370] to-[#016D3A] font-bold py-4 w-[360px] relative uppercase transition ease-in-out hover:scale-110 duration-200">
                            REGISTER Now
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                        </button>
                      </a>
                    </div>
                </div>
              </div>
            </div>
            <div class="block lg:hidden">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col items-center gap-6 mt-10">
                        <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                            <img src="../assets/metaspins-logo.png" class="w-[260px]">
                        </div>
                        <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                            <span class="text-4xl font-bold text-white text-center uppercase">register now on metaspins.com</span>
                            <span class="text-base text-white text-center uppercase">Receive 100% deposit bonus upon registration</span>
                        </div>
                        <div style="font-family: Oxanium, sans-serif;">
                            <a href="https://metamedialinks.com/e36249d00" target="_blank">
                                <button class="text-white bg-gradient-to-r from-[#01D370] to-[#016D3A] font-bold py-4 w-[280px] relative uppercase transition ease-in-out hover:scale-110 duration-200">
                                    REGISTER Now
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mx-auto">
                <div class="flex flex-col justify-center items-center text-center bg-gradient-to-tr rounded-2xl border from-[#FFFFFF]/10 to-[#1294F0]/10 py-14 mx-4 lg:mx-12 2xl:mx-[360px] mt-10 gap-10">
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-2xl md:text-3xl font-bold uppercase text-[#1294F0]">How to create an account on metaspins</span>
                        <span class="text-xl md:text-xl uppercase text-white">A STEP BY STEP GUIDE</span>
                    </div>
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-xl md:text-xl uppercase text-white">Ready to dive into the world of Metaspins?</span>
                        <span class="text-xl md:text-xl uppercase text-white">Follow this easy guide to create your account in just a few minutes.</span>
                    </div>
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-xl md:text-xl uppercase font-bold text-white">step 1: Visit metaspins.com</span>
                        <span class="text-xl md:text-xl uppercase text-white">Head over to the official Metaspins Casino website. Once you're there, click the</span>
                        <span class="text-xl md:text-xl uppercase text-white">"<span class="text-[#1294F0] font-bold">Register</span>" button at the top right corner to get started.</span>
                    </div>
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-xl md:text-xl uppercase font-bold text-white">step 2: fill in your details</span>
                        <span class="text-xl md:text-xl uppercase text-white">
                            Enter the necessary information, including your 
                            <span class="text-[#1294F0] font-bold">Email</span>,
                            <span class="text-[#1294F0] font-bold">Username</span>, 
                            <span class="text-[#1294F0] font-bold">Password</span>,
                        </span>
                        <span class="text-xl md:text-xl uppercase text-white">and <span class="text-[#1294F0] font-bold">Date of Birth</span>.</span>
                        <span class="text-xl md:text-xl uppercase text-white">Make sure everything is accurate for a smooth experience!</span>
                    </div>
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-xl md:text-xl font-bold text-white">step 3: enter code “<span class="text-[#1294F0] font-bold">Ballyboy</span>”</span>
                        <span class="text-xl md:text-xl uppercase text-white">Before finalizing your registration, enter the code</span> <span class="text-[#1294F0] font-bold">Ballyboy</span><span class="text-xl md:text-xl uppercase text-white"> in the</span>
                        <span class="text-xl md:text-xl uppercase text-white">promo field to access exclusive offers.</span>
                        <span class="text-xl md:text-xl uppercase text-white">Don't forget to agree to the terms & conditions before continuing.</span>
                    </div>
                    <div v-motion-slide-visible-bottom class="flex flex-col mx-4">
                        <span class="text-xl md:text-xl uppercase font-bold text-white">step 4: connect for extra perks</span>
                        <span class="text-xl md:text-xl uppercase text-white">Want to unlock even more? Connect your Metaspins account to <span class="text-[#1294F0] font-bold">ballyboy.store</span></span>
                        <span class="text-xl md:text-xl uppercase text-white">
                            to participate in 
                            <span class="text-[#1294F0] font-bold">Giveaways</span>,
                            <span class="text-[#1294F0] font-bold">Raffles</span>, 
                            and other <span class="text-[#1294F0] font-bold">community activities</span>.</span>
                        <span class="text-xl md:text-xl uppercase text-white">It's a great way to enhance your experience and stay in the loop!</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        isMobile() {
            return window.innerWidth < 1024;
        },
    }
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.frame-bg {
  background-image: url('../assets/metaspins-req-frame.png');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
