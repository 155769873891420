<template>
    <section :class="{'profile-bg': !isMobile(), 'profile-bg-mobile': isMobile() }" class="min-h-screen pb-32 lg:pb-44 2xl:pb-64 px-10">
			<div class="flex flex-col justify-center items-center pt-32 lg:pt-36 2xl:pt-64" style="font-family: Oxanium, sans-serif;">
				<h1 v-motion-pop-visible class="uppercase text-5xl lg:text-7xl font-bold mb-14 lg:mb-20 text-white">
					User <span class="text-[#1294F0]">Profile</span>
				</h1>
				<div v-motion-pop-visible class="w-full gap-6 max-w-[1270px] profile-card-bg border border-white border-opacity-40 rounded-[20px] overflow-hidden">
					<div class=" flex w-full justify-center flex-col gap-6 px-[15px] lg:px-[100px] py-[20px] lg:py-[84px] overflow-hidden rounded-[20px] backdrop-blur">
						<div class="flex flex-col lg:flex-row gap-6">
							<div class="profile-box lg:max-w-[420px]">
								<div class="h-full lg:max-h-[156px]">
									<img 
										src="../assets/discord-banner.png" 
										alt="discord banner image"
										class="object-cover w-full"
									>
								</div>
								<div class="flex flex-col text-white p-8 pt-4 lg:pt-16 relative">
									<div class="w-[80px] h-[80px] lg:w-[110px] lg:h-[110px] rounded-full mb-4 lg:border-[5px] border-[#222222] bg-[#222222] flex absolute -top-10 md:-top-10 lg:-top-16 left-8">
										<img :src="picUrl" class="bg-orange-200 w-full h-full rounded-full relative"/>
											<div class="absolute bottom-[1px] -right-[1.5px] border-[4px] lg:border-[5px] border-[#222222] bg-green-400 w-6 h-6 lg:w-7 lg:h-7 rounded-full" />
									</div>
									<h4 class="text-2xl font-bold mt-8 md:mt-10 lg:mt-0">
										{{ $store.state.user.username }}
									</h4>
									<p v-if="displayName !== null" class="text-sm md:text-base mb-2">
										ID:{{ discordID }}
									</p>
									<p class="text-lg">
										<span class="font-bold">Points:</span> {{ userPoints }}
									</p>
								</div>
							</div>
							<div class="flex-1 profile-box p-8">
								<div class="flex flex-row justify-between gap-4">
									<div>
										<h4 class="text-xl lg:text-4xl font-bold">
											{{ $store.state.user.username }}
										</h4>
										<span class="">Accounts</span>
									</div>
									<div>
										<button v-if="isEdit == false && loading == false" @click="isEdit = true" class="hover:cursor-pointer">
											<svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
												<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
												<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
												</svg>
										</button>
										<div v-if="isEdit == true && loading == false" class="flex flex-row gap-2">
											<button @click="saveProfile()" class="hover:cursor-pointer">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="limegreen" class="w-[30px]">
												<path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
												</svg>
											</button>
											<button @click="isEdit = false" class="hover:cursor-pointer">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" class="w-[30px]">
												<path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
												</svg>
											</button>
										</div>
										<div v-if="loading == true" role="status">
											<svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
											</svg>
											<span class="sr-only">Loading...</span>
										</div>
									</div>
									
								</div>
								<table>
									<tbody class="grid gap-8 mt-8">
										<tr class="flex flex-wrap gap-2 w-full items-center">
											<td class="text-2xl font-bold w-full max-w-[228px]">Metaspins <span class="text-sm">(Player ID)</span></td>
											<td>
												<input v-model="metaspins" :disabled="isEdit == false" class="lg:text-lg w-[270px] text-white px-2 h-10 lg:h-8" :class="isEdit == false ? 'bg-transparent' : 'bg-[#666666]'" type="text">
											</td>
											<!-- <td class="flex flex-1 items-center justify-end">
												<div class="w-4 h-4 hover:cursor-pointer">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
														<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
														<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
														</svg>
												</div>
											</td> -->
										</tr>
										<tr class="flex flex-wrap gap-2 w-full items-center">
											<td class="text-2xl font-bold w-full max-w-[228px]">Packdraw <span class="text-sm">(User ID)</span></td>
											<td>
												<input v-model="packdraw" :disabled="isEdit == false" class="lg:text-lg w-[270px] text-white px-2 h-10 lg:h-8" :class="isEdit == false ? 'bg-transparent' : 'bg-[#666666]'" type="text">
											</td>
											<!-- <td class="flex flex-1 items-center justify-end">
												<div class="w-4 h-4 hover:cursor-pointer">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
														<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
														<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
														</svg>
												</div>
											</td> -->
										</tr>
										<tr class="flex flex-wrap gap-2 w-full items-center">
											<td class="text-2xl font-bold w-full max-w-[228px]">Chicken.GG</td>
											<td>
												<input v-model="chickengg" :disabled="isEdit == false" class="lg:text-lg w-[270px] text-white px-2 h-10 lg:h-8" :class="isEdit == false ? 'bg-transparent' : 'bg-[#666666]'" type="text">
											</td>
											<!-- <td class="flex flex-1 items-center justify-end">
												<div class="w-4 h-4 hover:cursor-pointer">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
														<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
														<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
														</svg>
												</div>
											</td> -->
										</tr>
										<tr class="flex flex-wrap gap-2 w-full items-center">
											<td class="text-2xl font-bold w-full max-w-[228px]">Twitter/ X</td>
											<td>
												<input v-model="twitter" :disabled="isEdit == false" class="lg:text-lg w-[270px] text-white px-2 h-10 lg:h-8" :class="isEdit == false ? 'bg-transparent' : 'bg-[#666666]'" type="text">
											</td>
											<!-- <td class="flex flex-1 items-center justify-end">
												<div class="w-4 h-4 hover:cursor-pointer">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
														<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
														<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
														</svg>
												</div>
											</td> -->
										</tr>
										<tr class="flex flex-wrap gap-2 w-full items-center">
											<td class="text-2xl font-bold w-full max-w-[228px]">Kick</td>
											<td>
												<input v-model="kick" :disabled="isEdit == false" class="lg:text-lg w-[270px] text-white px-2 h-10 lg:h-8" :class="isEdit == false ? 'bg-transparent' : 'bg-[#666666]'" type="text">
											</td>
											<!-- <td class="flex flex-1 items-center justify-end">
												<div class="w-4 h-4 hover:cursor-pointer">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0007 15.1667H6.00065C2.38065 15.1667 0.833984 13.62 0.833984 10V6.00004C0.833984 2.38004 2.38065 0.833374 6.00065 0.833374H7.33398C7.60732 0.833374 7.83398 1.06004 7.83398 1.33337C7.83398 1.60671 7.60732 1.83337 7.33398 1.83337H6.00065C2.92732 1.83337 1.83398 2.92671 1.83398 6.00004V10C1.83398 13.0734 2.92732 14.1667 6.00065 14.1667H10.0007C13.074 14.1667 14.1673 13.0734 14.1673 10V8.66671C14.1673 8.39337 14.394 8.16671 14.6673 8.16671C14.9407 8.16671 15.1673 8.39337 15.1673 8.66671V10C15.1673 13.62 13.6207 15.1667 10.0007 15.1667Z" fill="white"/>
														<path d="M5.66688 11.7934C5.26022 11.7934 4.88688 11.6467 4.61355 11.38C4.28688 11.0534 4.14688 10.58 4.22022 10.08L4.50688 8.07337C4.56022 7.68671 4.81355 7.18671 5.08688 6.91337L10.3402 1.66004C11.6669 0.333372 13.0135 0.333372 14.3402 1.66004C15.0669 2.38671 15.3935 3.12671 15.3269 3.86671C15.2669 4.46671 14.9469 5.05337 14.3402 5.65337L9.08688 10.9067C8.81355 11.18 8.31355 11.4334 7.92688 11.4867L5.92022 11.7734C5.83355 11.7934 5.74688 11.7934 5.66688 11.7934ZM11.0469 2.36671L5.79355 7.62004C5.66688 7.74671 5.52022 8.04004 5.49355 8.21337L5.20688 10.22C5.18022 10.4134 5.22022 10.5734 5.32022 10.6734C5.42022 10.7734 5.58022 10.8134 5.77355 10.7867L7.78022 10.5C7.95355 10.4734 8.25355 10.3267 8.37355 10.2L13.6269 4.94671C14.0602 4.51337 14.2869 4.12671 14.3202 3.76671C14.3602 3.33337 14.1335 2.87337 13.6269 2.36004C12.5602 1.29337 11.8269 1.59337 11.0469 2.36671Z" fill="white"/>
														<path d="M13.233 6.55331C13.1864 6.55331 13.1397 6.54664 13.0997 6.53331C11.3464 6.03998 9.95305 4.64664 9.45971 2.89331C9.38638 2.62664 9.53971 2.35331 9.80638 2.27331C10.073 2.19998 10.3464 2.35331 10.4197 2.61998C10.8197 4.03998 11.9464 5.16664 13.3664 5.56664C13.633 5.63998 13.7864 5.91998 13.713 6.18664C13.653 6.41331 13.453 6.55331 13.233 6.55331Z" fill="white"/>
														</svg>
												</div>
											</td> -->
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="profile-box p-8">
							<h4 class="text-2xl font-bold mb-4">Disclaimer</h4>
							<ol class="grid gap-4">
								<li class="flex gap-2">
									<span>1. </span>
									Users must put the correct information to avoid discrepancies in points or else points won’t be distributed correctly
								</li>
								<li class="flex gap-2">
									<span>2. </span> Any loss of points, the bally boy team will not be held liable due to incorrect information entered on profile page (this is also a way to prevent alt accounts farming for points) so please be careful in entering the right information
								</li>
							</ol>
						</div>
					</div>
				</div>
				<!-- Leaving comment for Vercel Deployment-->
				<!-- <div class="flex flex-col justify-center items-center">
					<div class="mt-48"></div>
					<div
					v-motion-pop-visible
					class="text-white text-center text-[30px] lg:text-[40px] font-bold uppercase mt-10 md:w-1/2"
					style="font-family: Acme, sans-serif; line-height: 0.8"
					>
					Under construction. Coming soon...
					</div>
				</div> -->
				
			</div>
	</section>
</template>

<script>
import axios from 'axios';
export default {
	data(){
		return {
			displayName: null,
			picUrl: null,
			discordID: null,
            chickengg: null,
			metaspins: null,
			packdraw: null,
			twitter: null,
			kick: null,

			userPoints: 0,

			isEdit: false,
			loading: false,

			namePlace: "Edit Your Display Name"
		}
	},
	methods: {
		init() {
			const token = localStorage.getItem('ballyboy-access-token');
			if(token){
				axios.get(process.env.VUE_APP_BASE_API + '/api/user', {
					headers: {
						'x-api-key': process.env.VUE_APP_X_API_KEY,
						'Authorization': `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data) {
						this.displayName = res.data.user.username
						this.picUrl = 'https://cdn.discordapp.com/avatars/' +  res.data.user.discordId  + '/' +  res.data.user.profileImage  + '.png'
						this.discordID = res.data.user.discordId
                        this.chickengg = res.data.user.chickengg
						this.packdraw = res.data.user.packdraw
						this.metaspins = res.data.user.metaspins
						this.twitter = res.data.user.twitter
						this.kick = res.data.user.kick
					}
				}).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-access-token");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
			}
			else {
				localStorage.removeItem("vuex");
				localStorage.removeItem("ballyboy-access-token");
				window.location.reload();
			}
		},
		initUserPoints(){
            if(this.$store.state.user == null) return;

            const token = localStorage.getItem('ballyboy-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/user/points', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.userPoints = res.data.points
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
		isMobile() {
			return window.innerWidth < 1024;
		},
		saveProfile(){
				this.loading = true
				const token = localStorage.getItem('ballyboy-access-token');
				
				const formData = {
					packdraw: this.packdraw,
					chickengg: this.chickengg,
					metaspins: this.metaspins,
					twitter: this.twitter,
					kick: this.kick
				}
				axios.put(process.env.VUE_APP_BASE_API + '/api/user/update', formData, 
				{
					headers: {
						'x-api-key': process.env.VUE_APP_X_API_KEY,
						'Authorization': `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data) {
						this.init()
						this.loading = false
						this.isEdit = false
					}
				})
		}
	},
	mounted(){
		this.init()
		this.initUserPoints()
	}
}
</script>
<style scoped>

.profile-box {
	background-color: #222222;
	display: flex;
	flex-direction: column;
	color: white;
	border-radius: 10px;
	overflow: hidden;
}

.profile-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.profile-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.profile-card-bg {
	background: rgb(252,252,252);
	background: -moz-linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	background: -webkit-linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	background: linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcfcfc",endColorstr="#1294f0",GradientType=1);
	padding: 1px;
}
</style>
