<template>
    <div>
        <ProfileSection/>
    </div>
</template>

<script>
import ProfileSection from '../sections/ProfileSection.vue';

    export default {
        components: {
            ProfileSection
        }
    }
</script>

<style scoped>

</style>