<template>
    <section class="h-screen z-50 hero-bg">
        <div class="h-full">
            <div class="flex flex-col justify-center items-center">
                <img loading="lazy" src="../assets/ballyboy-logo.png" class="w-[300px] mt-24" alt="oeg">
                <div class="text-white text-[30px] mx-auto" style="font-family: Oxanium, sans-serif;">Loading....</div>
                <div class="mt-14 w-24 h-24 rounded-full animate-spin
                    border-y-8 border-solid border-white border-t-transparent">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
</style>