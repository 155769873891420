<template>
  <section :class="loading == true ? 'h-screen': 'h-auto'" class="container mx-auto" id="leaderboard">
      <div class="flex items-center justify-center space-x-8 border-b border-gray-600 mt-20 max-w-[1200px] mx-auto" style="font-family: Oxanium, sans-serif;">
          <div 
          class="cursor-pointer pb-2 relative text-white uppercase font-bold"
          :class="{'text-blue-500': activeTab === 'active'}" 
          @click="activeTab = 'active'; changeTab()"
          >
          Active
          <span 
              v-if="activeTab === 'active'" 
              class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
          ></span>
          </div>
          
          <div 
          class="cursor-pointer pb-2 relative text-white uppercase font-bold"
          :class="{'text-blue-500': activeTab === 'completed'}" 
          @click="activeTab = 'completed'; changeTab()"
          >
          Completed
          <span 
              v-if="activeTab === 'completed'" 
              class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
          ></span>
          </div>
      </div>
      <div v-if="loading == true" class="flex justify-center mt-14">
        <div class="w-24 h-24 rounded-full animate-spin
            border-y-8 border-solid border-white border-t-transparent">
        </div>
      </div>
      <div v-if="loading== false && currentCards.length == 0" class="h-screen flex justify-center mt-14" style="font-family: Oxanium, sans-serif;">
        <div class="text-center text-xl text-white font-bold py-4 uppercase">No Challenges to Show</div>
      </div>
      <div v-if="currentCards.length > 0" class="flex flex-wrap justify-center container mx-auto max-w-[1200px] mt-14 pb-14 lg:pb-20 gap-12">
          <div v-motion-pop-visible v-for="(cards, index) in currentCards" :key="index" class="flex flex-col w-[260px] md:w-[290px] rounded-t-2xl shadow-2xl overflow-hidden relative">
              <div class="relative">
              <img
                  :src="cards.imageUrl"
                  class="rounded-t-lg w-[290px] h-[290px] object-fill"
                  alt=""
              />
              <div v-if="cards.status == 'completed'">
                  <div class="absolute inset-0 flex items-center gap-1 text-white w-[290px] h-[290px] bg-black opacity-40"></div>
                  <div class="absolute inset-0 flex items-center gap-1 text-white w-[290px] h-[290px]">
                  <div class="flex flex-row items-center gap-1 ml-16 md:mx-auto">
                      <img src="../assets/trophy.png" class="w-5" alt="trophy">
                      <p class="font-base tracking-widest text-sm text-white" style="font-family: Russo One, sans-serif">{{ cards.completedBy }}</p>
                  </div>
                  </div>
              </div>
              </div>
              <div class="relative z-10 pl-6 pr-4 py-6 flex-grow flex flex-col gap-3 cards-frame -mt-4" style="font-family: Oxanium, sans-serif;">
                  <p class="font-black text-base text-left text-white uppercase">
                       {{ cards.title }}
                      </p>
                      <div class="flex flex-row items-center gap-2">
                      <img src="../assets/Flash.png" class="w-4" alt="flash">
                      <p class="font-base text-sm text-left text-white">
                          {{ cards.description }}
                      </p>
                      </div>
                      <!-- <div class="flex flex-row items-center gap-2">
                      <img src="../assets/PS2.png" class="w-5" alt="ps2">
                      <p class="font-base text-sm text-left text-white">
                          {{ cards.minimumBet }} Minimum Bet
                      </p>
                      </div> -->
                      <div class="flex flex-row items-center gap-2">
                      <img src="../assets/Gift.png" class="w-5" alt="gift">
                      <p class="font-base text-sm text-left text-white">
                          {{ cards.reward }} Token Rewards
                      </p>
                  </div>
              </div>
              <a v-if="cards.status == 'active'" :href="cards.challengeUrl" :class="[`text-white bg-gradient-to-r hover:bg-gradient-to-l font-bold text-base py-4 px-14 relative uppercase text-center cursor-pointer`, 
                    {'trim-bt-l from-[#F09C1E] to-[#FFDD15]': index % 4 === 0, 'trim-bt-r from-[#F09C1E] to-[#FFDD15]': index % 4 === 3, 'from-[#016D3A] to-[#01D370]': index % 4 === 1, 'from-[#165381] to-[#208EE2]': index % 4 === 2}]" style="font-family: Oxanium, sans-serif;">
                  Play now
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
              </a>
              <a v-if="cards.status == 'completed'" :class="[`text-white bg-gradient-to-r hover:bg-gradient-to-l font-bold text-base py-4 px-14 relative uppercase text-center cursor-pointer`, 
                    {'trim-bt-l from-[#F09C1E] to-[#FFDD15]': index % 4 === 0, 'trim-bt-r from-[#F09C1E] to-[#FFDD15]': index % 4 === 3, 'from-[#016D3A] to-[#01D370]': index % 4 === 1, 'from-[#165381] to-[#208EE2]': index % 4 === 2}]" style="font-family: Oxanium, sans-serif;">
                  Completed
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
              </a>
          </div>
      </div>
  </section>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
      return {
          loading: false,
          activeTab: 'active',

          currentCards: [],
          activeCards: [],
          completedCards: [],
      }
  },
  methods: {
      async init(){
          const baseUrl = process.env.VUE_APP_BASE_API;
          this.loading = true

          await axios.get(`${baseUrl}/api/challenge?status=active&type=chicken.gg`, 
          { headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
              this.activeCards = res.data.challenges
          });

          await axios.get(`${baseUrl}/api/challenge?status=completed&type=chicken.gg`, 
          { headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
              this.completedCards = res.data.challenges
          });

          this.changeTab()
          this.loading = false
      },
      changeTab(){
          if(this.activeTab == 'active') {
              this.currentCards = this.activeCards
          } 
          else{
              this.currentCards = this.completedCards
          }
      }
  },
  mounted(){
      this.init()
  }
})
</script>

<style scoped>
.cards-frame {
background-image: url('../assets/cards-frame.png');
background-repeat: no-repeat;
background-size: cover;
}

.trim-bt-l{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 80%);
}
.trim-bt-r {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}
</style>