<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-screen">
            <div class="pt-10 flex flex-col justify-center items-center" style="font-family: Oxanium, sans-serif;">
                <img loading="lazy" src="../assets/ballyboy-logo.png" class="w-[300px]" alt="ballyboy">
                <div class="text-white text-[50px] mx-auto text-center">Page Not Found</div>
                <div class="text-white text-lg mx-auto text-center px-6">
                    The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
                </div>
                <div class="mt-10">
                    <router-link to="/" class="px-4 py-3 text-white uppercase bg-[#1294F0]">
                        Back to Home
                    </router-link>
                </div>
            </div>
    </section>
</template>

<script>
    export default {
        methods: {
            isMobile() {
                return window.innerWidth < 1024;
            },
        }
    }
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>